const keys = {
    // API_URI: 'http://localhost:5000/api/v1',
    API_URI: 'https://back.flower.am/api/v1',
//    API_URI: 'http://45.93.138.35',
//    JWT_SECRET: 'fasjflkjasls*&*7342hjkhiefa7ndasj,a',
    CLOUDINARY_PRESENT: 'ijfszymq',
//    CLOUDINARY_CLOUD: 'dxlsyddqs'
CLOUDINARY_CLOUD: 'armcoding19'
}

export default keys;
