import axios from "axios";
import keys from "../../keys";
import {GET_ORDERS, GET_ORDER_IN_TABLE, DELETE_ORDER_FROM_TABLE, DELETE_ORDER} from "../types";
import Swal from "sweetalert2";
export const GET_ALL_ORDERS = 'GET_ALL_ORDERS'

export function getOrders() {
    return dispatch => {
        axios.get(`${keys.API_URI}/orders`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(keys.JWT_SECRET)}`
            },
        })
            .then(res => {
                dispatch({type: GET_ORDERS, payload: res.data.orders})
            })
            .catch(e => console.log(e))
    }
}

export function getAllOrders() {
    return dispatch => {
        axios.get(`${keys.API_URI}/ord/getAll`, )
            .then(res => {
                dispatch({type: GET_ALL_ORDERS, payload: res.data.orders})
            })
            .catch(e => console.log(e))
    }
}

export function getTableOrders(data) {
    return dispatch => {
        axios.get(`${keys.API_URI}/orders/table`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(keys.JWT_SECRET)}`
            },
            params: {products: data}
        })
            .then(res => {
                dispatch({type: GET_ORDER_IN_TABLE, payload: res.data})
            })
            .catch(e => console.log(e))
    }
}

export function deleteOrderFromTable() {
    return dispatch => {
        dispatch({type: DELETE_ORDER_FROM_TABLE})
    }
}


export function deleteOrder(id) {
    return dispatch => {
        axios.post(`${keys.API_URI}/orders/delete-order`, {
             data: {id}
        })
            .then(res => {
                dispatch({type: DELETE_ORDER, payload: id})
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    timer: 1500
                })
            })
            .catch(e => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    timer: 1500
                })
            })
    }
}

