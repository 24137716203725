import {BrowserRouter, Route, Routes} from 'react-router-dom'
import "./App.css"

// pages
import Layout from "./layouts/Layout";
import Users from "./pages/Users";
import Products from "./pages/Products";
import About from "./pages/About";
import Contacts from "./pages/Contacts";
import Orders from "./pages/Orders";
import Settings from "./pages/Settings";
import Test from './pages/Test';

function App() {
    return (
        <BrowserRouter>
            <Layout>
                <Routes>
                    <Route path={'/'} element={<Users />} exact/>
                    <Route path={'/products'} element={<Products />} exact/>
                    <Route path={'/about'} element={<About />} exact/>
                    <Route path={'/contact'} element={<Contacts />} exact/>
                    <Route path={'/orders'} element={<Orders />} exact/>
                    <Route path={'/settings'} element={<Settings />} exact/>
                    <Route path={'/test/:test'} element={<Test />} exact/>
                </Routes>
            </Layout>

        </BrowserRouter>
    );
}

export default App;
