import React, {useState} from 'react'
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {useDispatch} from "react-redux";
import Typography from "@mui/material/Typography";
import axios from "axios";
import keys from "../../keys";
import {updateAboutBanner} from "../../store/actions/settingAction";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const AboutBanner = ({aboutBanner}) => {
    const [value, setValue] = React.useState(0);
    const [data, setData] = useState(aboutBanner)

    const dispatch = useDispatch()

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleFile = e => {
        let file = e.target.files[0]
        uploadImage(file)
    }

    const uploadImage = file => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('upload_preset', keys.CLOUDINARY_PRESENT)
        axios.post(`https://api.cloudinary.com/v1_1/${keys.CLOUDINARY_CLOUD}/image/upload`, formData)
            .then(res => {
                data['image'] = res.data.url;
                setData(data)
            })
    }

    let handleChangeValues = e => {
        data[e.target.name] = e.target.value;
        setData(data)
    }

    let handleClick = () => {
        dispatch(updateAboutBanner(data))
    }

    return (
        <>
            <h4>About Banner</h4>
            <hr/>
            {
                data?.image
                    ? <FormControl fullWidth sx={{m: 1}}>
                        <img src={data.image}
                             style={{width: '100%', height: 'auto', objectFit: 'cover'}}
                        />
                    </FormControl>
                    : null
            }
            <FormControl fullWidth sx={{m: 1}}>
                <Button
                    variant="contained"
                    component="label"
                >
                    Upload Image
                    <input
                        type="file"
                        hidden
                        onChange={handleFile}
                    />
                </Button>
            </FormControl>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Հայերեն" {...a11yProps(0)} />
                    <Tab label="Русский" {...a11yProps(1)} />
                    <Tab label="English" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <FormControl fullWidth sx={{m: 1}}>
                    <InputLabel htmlFor="outlined-adornment-titleHy">Վերնագիր</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-titleHy"
                        label="Վերնագիր"
                        name={'titleHy'}
                        defaultValue={aboutBanner ? data.titleHy : ''}
                        onChange={handleChangeValues}
                    />
                </FormControl>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <FormControl fullWidth sx={{m: 1}}>
                    <InputLabel htmlFor="outlined-adornment-titleRu">Заголовок</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-titleRu"
                        label="Заголовок"
                        name={'titleRu'}
                        defaultValue={aboutBanner ? data.titleRu : ''}
                        onChange={handleChangeValues}
                    />
                </FormControl>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <FormControl fullWidth sx={{m: 1}}>
                    <InputLabel htmlFor="outlined-adornment-titleEn">Title</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-titleEn"
                        label="Title"
                        name={'titleEn'}
                        defaultValue={aboutBanner ? data.titleEn : ''}
                        onChange={handleChangeValues}
                    />
                </FormControl>
            </TabPanel>
            <Button
                variant="contained"
                component="label"
                onClick={handleClick}
            >Update</Button>
        </>
    )
}

export default AboutBanner