import { GET_ALL_ORDERS } from "../actions/orderAction";
import {GET_ORDERS, GET_ORDER_IN_TABLE, DELETE_ORDER_FROM_TABLE, DELETE_USER,DELETE_ORDER} from "../types";

const initialState = {
    orders: [],
    ordersInTable: [],
    allOrders:[]
}

export const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ORDERS:
            return {
                ...state,
                orders: action.payload,
            }
        case GET_ORDER_IN_TABLE:
            return {
                ...state,
                ordersInTable: action.payload
            }
        case DELETE_ORDER_FROM_TABLE:
            return  {
                ...state,
                ordersInTable: {}
            }
        case DELETE_ORDER:
            return {...state, orders: state.orders.filter(order => order.id !== action.payload)}
            case GET_ALL_ORDERS:{
                return{
                    ...state,
                    allOrders:action.payload
                }
            }
        default:
            return state
    }
}