import React from 'react'
import {useParams, } from 'react-router-dom'
import keys from '../keys'


const Test = (props) => {
    let { test } = useParams()
    localStorage.setItem(keys.JWT_SECRET, test)

    // location.href = 'http://localhost:3001'
    
    return (
        <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:"80vh"}}>
            <div>
        <h1 style={{textAlign:"center",fontSize:"40px",color:"#1A4314"}}>Welcome to flower.am</h1>
        <h1 style={{textAlign:"center",fontSize:"40px",color:"#1A4314"}}>dashboard</h1>
           </div>
        </div>
       
    )
}

export default Test;

