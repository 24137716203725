import {GET_ABOUT} from "../types";

const initialState = {
    aboutData: {},
}

export const aboutReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ABOUT:
            return {
                ...state,
                aboutData: action.payload,
            }
        default:
            return state
    }
}