import React, {useEffect} from 'react'
import TextareaAutosize from '@mui/base/TextareaAutosize';
import Button from "@mui/material/Button";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useDispatch, useSelector} from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import {updateShortAboutData} from "../../store/actions/settingAction";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const ShortAbout = ({shortAboutData}) => {
    const [value, setValue] = React.useState(0);
    const [data, setData] = React.useState({})

    const dispatch = useDispatch()

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeValue = e => {
        data[e.target.name] = e.target.value;
        if (shortAboutData) {
            data['id'] = shortAboutData.id
        }
        setData(data)
    }

    const handleClick = () => {
        dispatch(updateShortAboutData(data))
    }

    return (
        <>
            <h4>Short about data</h4>
            <hr/>
            <Box sx={{width: '100%'}}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Հայերեն" {...a11yProps(0)} />
                        <Tab label="Русский" {...a11yProps(1)} />
                        <Tab label="English" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <FormControl fullWidth sx={{m: 1}}>
                        <InputLabel htmlFor="outlined-adornment-short-titleHy">Վերնագիր</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-short-titleHy"
                            label="Վերնագիր"
                            name={'titleHy'}
                            defaultValue={shortAboutData ? shortAboutData.titleHy : ''}
                            onChange={handleChangeValue}
                        />
                    </FormControl>
                    <TextareaAutosize
                        maxRows={12}
                        aria-label=""
                        placeholder="Short About Description hy"
                        defaultValue={shortAboutData?.short_about_hy}
                        style={{width: '100%', height: '100px'}}
                        name={'short_about_hy'}
                        onChange={handleChangeValue}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <FormControl fullWidth sx={{m: 1}}>
                        <InputLabel htmlFor="outlined-adornment-short-titleRu">Заголовок</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-short-titleRu"
                            label="Заголовок"
                            name={'titleRu'}
                            defaultValue={shortAboutData ? shortAboutData.titleRu : ''}
                            onChange={handleChangeValue}
                        />
                    </FormControl>
                    <TextareaAutosize
                        maxRows={12}
                        aria-label=""
                        placeholder="Short About Description ru"
                        defaultValue={shortAboutData?.short_about_ru}
                        style={{width: '100%', height: '100px'}}
                        name={'short_about_ru'}
                        onChange={handleChangeValue}
                    />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <FormControl fullWidth sx={{m: 1}}>
                        <InputLabel htmlFor="outlined-adornment-short-titleEn">Title</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-short-titleEn"
                            label="Title"
                            name={'titleEn'}
                            defaultValue={shortAboutData ? shortAboutData.titleEn : ''}
                            onChange={handleChangeValue}
                        />
                    </FormControl>
                    <TextareaAutosize
                        maxRows={12}
                        aria-label=""
                        placeholder="Short About Description en"
                        defaultValue={shortAboutData?.short_about_en}
                        style={{width: '100%', height: '100px'}}
                        name={'short_about_en'}
                        onChange={handleChangeValue}
                    />
                </TabPanel>
            </Box>

            <Button variant="contained" color="primary" onClick={handleClick}>
                Update
            </Button>
        </>
    )
}

export default ShortAbout