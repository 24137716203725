import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {getAboutBanner, getHomeBanner, getShortAboutData} from "../store/actions/settingAction";
import HomeBanner from "../components/Setting/HomeBanner";
import AboutBanner from "../components/Setting/AboutBanner";
import ShortAbout from "../components/Setting/ShortAbout";
import Categories from '../components/Setting/Categories';
import Information from "../components/Setting/Information"
import Services from "../components/Setting/Services"
const Settings = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getHomeBanner())
        dispatch(getAboutBanner())
        dispatch(getShortAboutData())
    }, [])

    const homeBanner = useSelector(state => state.setting.homeBanner)

    const aboutBanner = useSelector(state => state.setting.aboutBanner)

    const shortAboutData = useSelector(state => state.setting.shortAboutData)

    return (
        <>
            <HomeBanner homeBanner={homeBanner}/>
            <AboutBanner aboutBanner={aboutBanner}/>
            <ShortAbout shortAboutData={shortAboutData}/>
            <Categories/>
            <Information/>
            <Services/>
        </>
    )
}

export default Settings