import {GET_USERS, DELETE_USER, CREATE_USER} from "../types";

const initialState = {
    users: [],
    pageCount: null
}

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USERS:
            return {
                ...state,
                users: action.payload.users,
                // pageCount: action.payload.pageCount
            }
        case DELETE_USER:
            return {...state, users: state.users.filter(user => user.id !== action.payload)}
        case CREATE_USER:
            return {...state, users: [{...action.payload}, ...state.users]}
        default:
            return state
    }
}