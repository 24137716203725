import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, TextField } from "@mui/material";
import axios from "axios";
import keys from "../../keys";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Information() {
  const [value, setValue] = React.useState(0);
  const [titleHy, setTitleHy] = useState("");
  const [titleRu, setTitleRu] = useState("");
  const [titleEn, setTitleEn] = useState("");
  const [descriptionHy, setDecriptionHy] = useState("");
  const [descriptionRu, setDecriptionRu] = useState("");
  const [descriptionEn, setDecriptionEn] = useState("");
  const [info, setInfo] = useState();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    axios
      .get(`${keys.API_URI}/information`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(keys.JWT_SECRET)}`,
        },
      })
      .then(function (response) {
        setInfo(response.data);
      });
  }, []);

  const handleClick = () => {
    axios
      .post(
        `${keys.API_URI}/information/`,
        {
          titleHy,
          titleRu,
          titleEn,
          descriptionHy,
          descriptionRu,
          descriptionEn,
          image: "",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(keys.JWT_SECRET)}`,
          },
        }
      )
      .then(function (response) {})
      .catch(function (error) {});
  };

  return (
    <>
      <h4>Information</h4>
      <hr />
      <ul>
        {info &&
          info.length &&
          info.map((i) => {
            return (
              <div key={i.id}>
                <li>
                  {i && i.titleHy} <br />
                  {i && i.descriptionHy}
                </li>
              </div>
            );
          })}
      </ul>
      <hr />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Հայերեն" {...a11yProps(0)} />
            <Tab label="Русский" {...a11yProps(1)} />
            <Tab label="English" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <TextField
            id="outlined-basic"
            label="Outlined"
            variant="outlined"
            value={titleHy}
            onChange={(e) => setTitleHy(e.target.value)}
            style={{ width: "100%", margin: "10px 0" }}
          />{" "}
          <br />
          <textarea
            value={descriptionHy}
            onChange={(e) => setDecriptionHy(e.target.value)}
            style={{ width: "100%" }}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TextField
            id="outlined-basic"
            label="Outlined"
            variant="outlined"
            value={titleRu}
            onChange={(e) => setTitleRu(e.target.value)}
            style={{ width: "100%", margin: "10px 0" }}
          />{" "}
          <br />
          <textarea
            value={descriptionRu}
            onChange={(e) => setDecriptionRu(e.target.value)}
            style={{ width: "100%" }}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TextField
            id="outlined-basic"
            label="Outlined"
            variant="outlined"
            value={titleEn}
            onChange={(e) => setTitleEn(e.target.value)}
            style={{ width: "100%", margin: "10px 0" }}
          />{" "}
          <br />
          <textarea
            value={descriptionEn}
            onChange={(e) => setDecriptionEn(e.target.value)}
            style={{ width: "100%" }}
          />
        </TabPanel>

        <Button variant="contained" color="primary" onClick={handleClick}>
          Update
        </Button>
      </Box>
    </>
  );
}

export default Information;
