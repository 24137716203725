import axios from "axios";
import keys from "../../keys";
import {UPDATE_CONTACT, GET_CONTACT} from "../types";
import Swal from "sweetalert2";

export function updateContact(data) {
    return dispatch => {
        axios.patch(`${keys.API_URI}/contact`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(keys.JWT_SECRET)}`
            },
        })
            .then(res => {
                dispatch({type: UPDATE_CONTACT, payload: data})
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    timer: 1500
                })
            })
            .catch(e => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    timer: 1500
                })
            })
    }
}

export function getContactData() {
    return dispatch => {
        axios.get(`${keys.API_URI}/contact`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(keys.JWT_SECRET)}`
            },
        })
            .then(res => {
                dispatch({type: GET_CONTACT, payload: res.data[0]})
            })
            .catch(e => console.log(e))
    }
}