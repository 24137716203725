export const GET_USERS = 'GET_USERS'
export const DELETE_USER = 'DELETE_USER'
export const DELETE_ORDER = 'DELETE_ORDER'
export const CREATE_USER = 'CREATE_USER'

export const UPDATE_ABOUT = 'UPDATE_ABOUT'
export const GET_ABOUT = 'GET_ABOUT'

export const UPDATE_CONTACT = 'UPDATE_CONTACT'
export const GET_CONTACT = 'GET_CONTACT'

export const GET_PRODUCTS = 'GET_PRODUCTS'
export const EDIT_PRODUCTS = 'EDIT_PRODUCTS'
export const DELETE_PRODUCTS = 'DELETE_PRODUCTS'
export const CREATE_PRODUCTS = 'CREATE_PRODUCTS'

export const GET_ORDERS = 'GET_ORDERS'
export const UPDATE_ORDERS = 'UPDATE_ORDERS'
export const GET_ORDER_IN_TABLE = 'GET_ORDER_IN_TABLE'
export const DELETE_ORDER_FROM_TABLE = 'DELETE_ORDER_FROM_TABLE'

export const GET_HOME_BANNER = 'GET_HOME_BANNER'
export const UPDATE_HOME_BANNER = 'UPDATE_HOME_BANNER'

export const GET_ABOUT_BANNER = 'GET_ABOUT_BANNER'
export const UPDATE_ABOUT_BANNER = 'UPDATE_ABOUT_BANNER'

export const GET_SHORT_ABOUT_BANNER = 'GET_SHORT_ABOUT_BANNER'
export const UPDATE_SHORT_ABOUT_BANNER = 'UPDATE_SHORT_ABOUT_BANNER'

export const CREATE_CATEGORY = 'CREATE_CATEGORY'
export const GET_CATEGORIES = 'GET_CATEGORIES'

export const GET_SERVICES = "GET_SERVICES"