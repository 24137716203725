import axios from "axios";
import keys from "../../keys";
import {GET_PRODUCTS, EDIT_PRODUCTS, DELETE_PRODUCTS, CREATE_PRODUCTS} from "../types";
import Swal from "sweetalert2";

export function getProducts() {
    return dispatch => {
        axios.get(`${keys.API_URI}/product/admin/all`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(keys.JWT_SECRET)}`
            },
        })
            .then(res => {
                dispatch({type: GET_PRODUCTS, payload: res.data.products})
            })
            .catch(e => console.log(e))
    }
}

export function deleteProducts(id) {
    return dispatch => {
        axios.delete(`${keys.API_URI}/product/`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(keys.JWT_SECRET)}`
            },
            data: {id}
        })
            .then(res => {
                dispatch({type: DELETE_PRODUCTS, payload: id})
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    timer: 1500
                })
            })
            .catch(e => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    timer: 1500
                })
            })
    }
}

export function createProduct(data) {
    return dispatch => {
        axios.post(`${keys.API_URI}/product`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(keys.JWT_SECRET)}`
            },
        })
            .then(res => {
                dispatch({type: CREATE_PRODUCTS, payload: res.data})
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    timer: 1500
                })
            })
            .catch(e => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    timer: 1500
                })
            })
    }
}

export function updateProduct(data) {
    return dispatch => {
        axios.put(`${keys.API_URI}/product`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(keys.JWT_SECRET)}`
            },
        })
            .then(res => {
                dispatch({type: EDIT_PRODUCTS, payload: data})
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    timer: 1500
                })
            })
            .catch(e => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    timer: 1500
                })
            })
    }
}