import React, {useEffect} from 'react'
import TableCell from "@mui/material/TableCell";
import ToggleButton from "@mui/material/ToggleButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import {useDispatch} from "react-redux";
import {deleteProducts} from "../../store/actions/productAction";
import ImageListItem from "@mui/material/ImageListItem";
import ImageList from "@mui/material/ImageList";

const ProductItem = ({row, handleOpen}) => {
    const dispatch = useDispatch()

    useEffect(() => {

    }, [row])


    const handleDelete = () => {
        dispatch(deleteProducts(row.id))
    }

    const handleEditModal = () => {

    }

    let images = row.images.split(',')
    useEffect(() => {
        console.clear()
      }, []);
    return (
        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
            <TableCell key={row.id}>
                <ImageList sx={{width: 400, height: 150}} cols={2} rowHeight={164}>
                    {images.map((item) => (
                        <ImageListItem key={item}>
                            <img
                                src={`${item}?w=164&h=164&fit=crop&auto=format`}
                                // srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                alt={item}
                                loading="lazy"
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </TableCell>
            <TableCell key={row.id}>
                {row.titleHy}
            </TableCell>
            <TableCell key={row.id}>
                {row.availabilityHy}
            </TableCell>
            <TableCell key={row.id}>
                {row.model}
            </TableCell>
            <TableCell key={row.id}>
                {row.price_amd}
            </TableCell>
            <TableCell key={row.id}>
                <ToggleButton value="left" key="left" onClick={handleEditModal}>
                    <EditIcon onClick={() => handleOpen({type: 'edit', product: row})}/>
                </ToggleButton>
                <ToggleButton value="left" key="left" onClick={handleDelete}>
                    <DeleteOutlineIcon/>
                </ToggleButton>
            </TableCell>
        </TableRow>
    )
}

export default ProductItem