import React, {useEffect} from 'react'
import TextareaAutosize from '@mui/base/TextareaAutosize';
import Button from "@mui/material/Button";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useDispatch, useSelector} from "react-redux";
import {getAboutData, updateAbout} from "../store/actions/aboutAction";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const About = () => {
    const [value, setValue] = React.useState(0);
    const [data, setData] = React.useState({})

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAboutData())
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const aboutData = useSelector(state => state.about.aboutData)

    const handleChangeValue = e => {
        data[e.target.name] = e.target.value;
        if(aboutData) {
            data['id'] = aboutData.id
        }
        setData(data)
    }

    const handleClick = () => {
        dispatch(updateAbout(data))
    }

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Հայերեն" {...a11yProps(0)} />
                        <Tab label="Русский" {...a11yProps(1)} />
                        <Tab label="English" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <TextareaAutosize
                        maxRows={12}
                        aria-label=""
                        placeholder="About Description hy"
                        defaultValue={aboutData?.descriptionHy}
                        style={{ width: '100%', height: 500 }}
                        name={'descriptionHy'}
                        onChange={handleChangeValue}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <TextareaAutosize
                        maxRows={12}
                        aria-label=""
                        placeholder="About Description ru"
                        defaultValue={aboutData?.descriptionRu}
                        style={{ width: '100%', height: 500 }}
                        name={'descriptionRu'}
                        onChange={handleChangeValue}
                    />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <TextareaAutosize
                        maxRows={12}
                        aria-label=""
                        placeholder="About Description en"
                        defaultValue={aboutData?.descriptionEn}
                        style={{ width: '100%', height: 500 }}
                        name={'descriptionEn'}
                        onChange={handleChangeValue}
                    />
                </TabPanel>
            </Box>

            <Button variant="contained" color="primary" onClick={handleClick}>
                Update
            </Button>
        </>
    )
}

export default About