import React, {useEffect, useState} from "react";

import TextareaAutosize from '@mui/base/TextareaAutosize';
import Button from "@mui/material/Button";
import keys from "../../keys"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useDispatch, useSelector} from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import {createCategory, getCategories} from "../../store/actions/settingAction";
import axios from "axios"
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Categories = () => {
    const [value, setValue] = React.useState(0);
    const [category, setCategory] = React.useState({})
    const [current, setCurrent] = useState(0)
    const [open, setOpen] = React.useState(false);
    const [data, setData] = useState({})
    const handleClose = () => setOpen(false);

    const dispatch = useDispatch()
    const categories = useSelector(state => state.setting.categories)

    const handleOpen = (id) => {
        setOpen(true)
        let currentCategory = categories.find(i => i.id == id)
        setCurrent(currentCategory)
    };

    useEffect(() => {
        dispatch(getCategories())
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeCategory = (e) => {
        data["id"] = current.id
        data[e.target.name] = e.target.value;
        setData(data)
    }


    const handleChangeValue = e => {
        category[e.target.name] = e.target.value;
        setCategory(category)
    }

    const handleClick = () => {
        dispatch(createCategory(category))
    }

    const handleEdit = () => {
        axios.post(`${keys.API_URI}/category/edit`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(keys.JWT_SECRET)}`
            },
        })
            .then(function (response) {
                setOpen(false)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleDelete = (id) => {
        axios.delete(`${keys.API_URI}/contact/`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(keys.JWT_SECRET)}`
            },
            data: {id}
        })
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (

        <>
            <h4>Categories</h4>
            <hr/>
            <ul>
                {
                    categories.length
                        ? <Box sx={{width: '100%'}}>
                            {
                                categories.map(category => <li style={{
                                        marginTop: "10px"
                                    }}
                                                               key={category.id}>{category.categoryHy},{category.categoryRu},{category.categoryEn}
                                        <Button onClick={() => handleOpen(category.id)}>Edit</Button>
                                        <Button onClick={() => handleDelete(category.id)} variant="outlined" color="error"
                                                color="error">Delete</Button>

                                    </li>
                                )
                            }
                        </Box>
                        : null
                }
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <TextField id="outlined-basic" label="Outlined" variant="outlined" name={"categoryHy"}
                                   defaultValue={current?.categoryHy}
                                   onChange={handleChangeCategory} style={{marginTop: "10px"}}
                        />
                        <TextField id="outlined-basic" style={{marginTop: "10px"}} onChange={handleChangeCategory}
                                   label="Outlined" variant="outlined" name={"categoryRu"}
                                   defaultValue={current?.categoryRu}/>
                        <TextField id="outlined-basic" style={{marginTop: "10px"}} label="Outlined" variant="outlined"
                                   onChange={handleChangeCategory} name={"categoryEn"}
                                   defaultValue={current?.categoryEn}/>
                        <br/> <Button onClick={handleEdit}>Edit</Button>
                    </Box>
                </Modal>
            </ul>


            <Box sx={{width: '100%'}}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Հայերեն" {...a11yProps(0)} />
                        <Tab label="Русский" {...a11yProps(1)} />
                        <Tab label="English" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <FormControl fullWidth sx={{m: 1}}>
                        <InputLabel htmlFor="outlined-adornment-short-titleHy">Վերնագիր</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-short-titleHy"
                            label="Վերնագիր"
                            name={'categoryHy'}
                            onChange={handleChangeValue}
                        />
                    </FormControl>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <FormControl fullWidth sx={{m: 1}}>
                        <InputLabel htmlFor="outlined-adornment-short-titleRu">Заголовок</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-short-titleRu"
                            label="Заголовок"
                            name={'categoryRu'}
                            onChange={handleChangeValue}
                        />
                    </FormControl>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <FormControl fullWidth sx={{m: 1}}>
                        <InputLabel htmlFor="outlined-adornment-short-titleEn">Title</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-short-titleEn"
                            label="Title"
                            name={'categoryEn'}
                            onChange={handleChangeValue}
                        />
                    </FormControl>
                </TabPanel>
            </Box>

            <Button variant="contained" color="primary" onClick={handleClick}>
                Update
            </Button>
        </>
    )
}


export default Categories;