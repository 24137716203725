import React from 'react'
import TableCell from "@mui/material/TableCell";
import ToggleButton from "@mui/material/ToggleButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import TableRow from "@mui/material/TableRow";
import {useDispatch} from "react-redux";

import {deleteUser} from "../../store/actions/userAction";

const UserItem = ({row}) => {

    const dispatch = useDispatch()
    const deleteUserHandler = () => dispatch(deleteUser(row.id))

    return (
        <TableRow>
            <TableCell component="th" scope="row">
                {row.email}
            </TableCell>
            <TableCell style={{ width: 160 }} align="right">
                {row.role ? 'Administrator': 'User'}
            </TableCell>
            <TableCell style={{ width: 160 }} align="right">
                <ToggleButton value="left" key="left" onClick={deleteUserHandler}>
                    <DeleteOutlineIcon />
                </ToggleButton>
            </TableCell>
        </TableRow>
    )
}

export default UserItem