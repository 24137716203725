import * as React from 'react';
import {useEffect} from "react"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ProductItem from "./ProductItem";
import {useSelector} from "react-redux";

const columns = [
    {id: 'Images', label: 'Images', minWidth: 100},
    {id: 'Title', label: 'Title', minWidth: 170},
    {id: 'Availability', label: 'Availability', minWidth: 100},
    {id: 'model', label: 'Model', minWidth: 100},
    {id: 'price_amd', label: 'AMD', minWidth: 100},
    {id: 'actions', label: 'Actions', minWidth: 100},
];

function createData(product) {
    return {
        id: product.id,
        availabilityEn: product.availabilityEn,
        availabilityHy: product.availabilityHy,
        availabilityRu: product.availabilityRu,
        images: product.images,
        model: product.model,
        price_amd: product.price_amd,
        price_eur: product.price_eur,
        price_rub: product.price_rub,
        price_usd: product.price_usd,
        titleEn: product.titleEn,
        titleHy: product.titleHy,
        titleRu: product.titleRu,
    };
}


export default function ProductTable({handleOpen}) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const products = useSelector(state => state.product.products)

    const rows = products.map(product => createData(product))

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    useEffect(() => {
        console.clear()
      }, []);

    return (
        <Paper sx={{width: '100%', overflow: 'hidden'}}>
             <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <TableContainer sx={{maxHeight: 440}}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={Math.random()}
                                    align={column.align}
                                    style={{minWidth: column.minWidth}}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {products
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(row => <ProductItem row={row} key={Math.random()} handleOpen={handleOpen}/>)
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}