import axios from "axios";
import keys from "../../keys";
import {UPDATE_HOME_BANNER, GET_HOME_BANNER,GET_SERVICES, GET_ABOUT_BANNER, UPDATE_ABOUT_BANNER, UPDATE_SHORT_ABOUT_BANNER, GET_SHORT_ABOUT_BANNER, GET_CATEGORIES, CREATE_CATEGORY} from "../types";
import Swal from "sweetalert2";


export function getHomeBanner() {
    return dispatch => {
        axios.get(`${keys.API_URI}/settings/banner/home/get`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(keys.JWT_SECRET)}`
            },
        })
            .then(res => {
                dispatch({type: GET_HOME_BANNER, payload: res.data[0]})
            })
            .catch(e => console.log(e))
    }
}

export const getServicesThunk = () => {
    return async (dispatch) => {
        const response = await axios.get(`${keys.API_URI}/services/all`)
        dispatch({
            type: GET_SERVICES,
            payload: response.data,
        });
    };
}

export function updateHomeBanner(data) {
    return dispatch => {
        axios.post(`${keys.API_URI}/settings/banner/home/create`, data,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem(keys.JWT_SECRET)}`
            },
        })
            .then(res => {
                dispatch({type: UPDATE_HOME_BANNER, payload: data})
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    timer: 1500
                })
            })
            .catch(e => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    timer: 1500
                })
            })
    }
}

export function getAboutBanner() {
    return dispatch => {
        axios.get(`${keys.API_URI}/settings/banner/about/get`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(keys.JWT_SECRET)}`
            },
        })
            .then(res => {
                dispatch({type: GET_ABOUT_BANNER, payload: res.data[0]})
            })
            .catch(e => console.log(e))
    }
}

export function updateAboutBanner(data) {
    return dispatch => {
        axios.post(`${keys.API_URI}/settings/banner/about/create`, data,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem(keys.JWT_SECRET)}`
            },
        })
            .then(res => {
                dispatch({type: UPDATE_ABOUT_BANNER, payload: res.data})
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    timer: 1500
                })
            })
            .catch(e => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    timer: 1500
                })
            })
    }
}

export function getShortAboutData() {
    return dispatch => {
        axios.get(`${keys.API_URI}/settings/about/short`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(keys.JWT_SECRET)}`
            },
        })
            .then(res => {
                dispatch({type: GET_SHORT_ABOUT_BANNER, payload: res.data[0]})
            })
            .catch(e => console.log(e))
    }
}

export function updateShortAboutData(data) {
    return dispatch => {
        axios.post(`${keys.API_URI}/settings/about/short`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(keys.JWT_SECRET)}`
            },
        })
            .then(res => {
                dispatch({type: UPDATE_SHORT_ABOUT_BANNER, payload: res.data[0]})
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    timer: 1500
                })
            })
            .catch(e => {
                console.log(e)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    timer: 1500
                })
            })
    }
}

export function createCategory(data) {
    return dispatch => {
        axios.post(`${keys.API_URI}/category`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(keys.JWT_SECRET)}`
            },
        })
            .then(res => {
                dispatch({type: CREATE_CATEGORY, payload: res.data})
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    timer: 1500
                })
            })
            .catch(e => {
                console.log(e)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    timer: 1500
                })
            })
    }
}

export function getCategories() {
    return dispatch => {
        axios.get(`${keys.API_URI}/category`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(keys.JWT_SECRET)}`
            },
        }).then(res => {
            dispatch({type: GET_CATEGORIES, payload: res.data})
        })
            .catch(e => console.log(e))

    }
}
