    import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import axios from "axios";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { useEffect } from "react";
import {
  createProduct,
  updateProduct,
} from "../../store/actions/productAction";
import keys from "../../keys";
import { MenuItem, Select } from "@mui/material";
import { getCategories } from "../../store/actions/settingAction";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ProductModal({
  handleOpen,
  handleClose,
  open,
  modalType,
  productItemInModal,
}) {
  const [data, setData] = useState({});
  const categories = useSelector((state) => state.setting.categories);
  const [value, setValue] = React.useState(0);
  const [val, setVal] = React.useState({});
  const [categorie, setCategori] = useState();

  useEffect(() => {
    setVal(data);
  }, [data]);


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategories());
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    console.clear()
  }, []);
  const editComponent = () => {
    let images = productItemInModal?.images.split(",");

    
    return (
      <Box sx={{ width: "100%" }}>
        <ImageList sx={{ width: "100%", height: 150 }} cols={2} rowHeight={164}>
          {images?.map((item) => (
            <ImageListItem key={item}>
              <img
                src={`${item}?w=164&h=164&fit=crop&auto=format`}
                srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                alt={item}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
        <FormControl fullWidth sx={{ m: 1 }}>
          <Button variant="contained" component="label">
            Upload Images
            <input type="file" hidden multiple onChange={handleFile} />
          </Button>
        </FormControl>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Հայերեն" {...a11yProps(0)} />
            <Tab label="Русский" {...a11yProps(1)} />
            <Tab label="English" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel htmlFor="outlined-adornment-titleHy">
              Վերնագիր
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-titleHy"
              label="Վերնագիր"
              name={"titleHy"}
              defaultValue={productItemInModal?.titleHy}
              value={val?.titleHy}
              onChange={handleChangeValues}
            />
          </FormControl>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel htmlFor="outlined-adornment-availabilityHy">
              Առկա է
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-availabilityHy"
              label="Առկա է"
              name={"availabilityHy"}
              defaultValue={productItemInModal?.availabilityHy}
              onChange={handleChangeValues}
            />
          </FormControl>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel htmlFor="outlined-adornment-titleRu">
              Заголовок
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-titleRu"
              label="Заголовок"
              name={"titleRu"}
              value={val?.titleRu}
              defaultValue={productItemInModal?.titleRu}
              onChange={handleChangeValues}
            />
          </FormControl>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel htmlFor="outlined-adornment-availabilityRu">
              Доступен
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-availabilityRu"
              label="Доступен"
              name={"availabilityRu"}
              defaultValue={productItemInModal?.availabilityRu}
              onChange={handleChangeValues}
            />
          </FormControl>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel htmlFor="outlined-adornment-titleEn">Title</InputLabel>
            <OutlinedInput
              id="outlined-adornment-titleEn"
                 label="Title"
              name={"titleEn"}
              value={val?.titleEn}
              defaultValue={productItemInModal?.titleEn}
              onChange={handleChangeValues}
            />
          </FormControl>
          <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="outlined-adornment-availabilityEn">
              Availability
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-availabilityEn"
              label="Availability"
              name={"availabilityEn"}
              defaultValue={productItemInModal?.availabilityEn}
              onChange={handleChangeValues}
            />
          </FormControl>
        </TabPanel>

        <FormControl fullWidth sx={{ m: 1 }}>
          <InputLabel htmlFor="outlined-adornment-price_amd">
            Price (AMD)
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-price_amd"
            label="Price (AMD)"
            name={"price_amd"}
            defaultValue={productItemInModal?.price_amd}
            onChange={handleChangeValues}
          />
        </FormControl>
   
        <Button variant="outlined" onClick={handleCreate}>
          Create
        </Button>
      </Box>
    );
  };


  const handleChangeValues = (e) => {
    data[e.target.name] = e.target.value;
    if (productItemInModal) data["id"] = productItemInModal?.id;
    setData(data);
  };

  const handleFile = (e) => {
    let files = [];
    Object.keys(e.target.files).map((f) => {
      if (f === "Length") return;
      files.push(e.target.files[f]);
    });
    uploadImage(files);
  };

  let arrOfImages = [];
  const uploadImage = (files) => {
    files.forEach((file) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset",  'ijfszymq');
formData.append("api_key", "735499719383658");
      axios
        .post(
          `https://api.cloudinary.com/v1_1/${keys.CLOUDINARY_CLOUD}/image/upload`,
          formData
        )
        .then((res) => {
          arrOfImages.push(res.data.url);
          data["images"] = arrOfImages;
          setData(data);
        });
    });
  };

  const handleCreate = () => {
    if (productItemInModal) dispatch(updateProduct(data));
    else dispatch(createProduct(data));

    handleClose();
  };


  const uploadedImages = (arr) => {
    return (
      <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
        {arr.map((item) => (
          <ImageListItem key={item}>
            <img
              src={`${item}?w=164&h=164&fit=crop&auto=format`}
              srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              alt={item}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    );
  };

  const createComponent = () => {
    return (
      <Box sx={{ width: "100%" }}>
        <FormControl fullWidth sx={{ m: 1 }}>
          <Button variant="contained" component="label">
            Upload Images
            <input type="file" hidden multiple onChange={handleFile} />
          </Button>
        </FormControl>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab  label="Հայերեն" {...a11yProps(20)} />
            <Tab  label="Русский" {...a11yProps(21)} />
            <Tab  label="English" {...a11yProps(22)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel htmlFor="outlined-adornment-titleHy">
              Վերնագիր
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-titleHy"
              label="Վերնագիր"
              name={"titleHy"}
              defaultValue={val?.titleHy}
              onChange={handleChangeValues}
            />
          </FormControl>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel htmlFor="outlined-adornment-availabilityHy">
              Առկա է
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-availabilityHy"
              label="Առկա է"
              defaultValue="Առկա է"
              name={"availabilityHy"}
              onChange={handleChangeValues}
            />
          </FormControl>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel htmlFor="outlined-adornment-titleRu">
              Заголовок
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-titleRu"
              label="Заголовок"
              name={"titleRu"}
              defaultValue={val?.titleRu}
              onChange={handleChangeValues}
            />
          </FormControl>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel htmlFor="outlined-adornment-availabilityRu">
              Доступен
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-availabilityRu"
              label="Доступен"
              defaultValue="Доступен"
              name={"availabilityRu"}
              onChange={handleChangeValues}
            />
          </FormControl>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel htmlFor="outlined-adornment-titleEn">Title</InputLabel>
            <OutlinedInput
              id="outlined-adornment-titleEn"
              label="Title"
              name={"titleEn"}
              // value={val?.availabilityHy}
              defaultValue={val?.titleEn}
              onChange={handleChangeValues}
            />
          </FormControl>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel htmlFor="outlined-adornment-availabilityEn">
              Availability
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-availabilityEn"
              label="Availability"
              defaultValue='Availability'
              name={"availabilityEn"}
              onChange={handleChangeValues}
            />
          </FormControl>
        </TabPanel>

        <FormControl fullWidth sx={{ m: 1 }}>
          <InputLabel id="demo-simple-select-label">Category</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Category"
            onChange={(e) => {
              data["category"] = e.target.value;
              setData(data);
            }}
          >
            {categories.map((categorie) => {
              return (
                <MenuItem value={categorie.id} key={categorie.id}>{categorie.categoryHy}</MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ m: 1 }}>
          <InputLabel htmlFor="outlined-adornment-price_amd">
            Price (AMD)
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-price_amd"
            label="Price (AMD)"
            name={"price_amd"}
            onChange={handleChangeValues}
          />
        </FormControl>
        <Button variant="outlined" onClick={handleCreate}>
          Create
        </Button>
      </Box>
    );
  };

  return (
    <div>
      <Button onClick={() => handleOpen({ type: "create" })}>
        <AddCircleIcon />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {modalType === "create" ? createComponent() : editComponent()}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
