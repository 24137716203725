import React, {useEffect} from 'react'

import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import {getContactData, updateContact} from "../store/actions/contactAction";

const Contacts = () => {
    const [data, setData] = React.useState({})

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getContactData())
    }, [])

    const contactData = useSelector(state => state.contact.contactData)

    const handleChangeValues = e => {
        data[e.target.name] = e.target.value;
        if (contactData) {
            data['id'] = contactData.id
        }
        setData(data)
    }

    const handleClick = () => {
        dispatch(updateContact(data))
    }

    return (
        <>
            <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                <div>
                    <FormControl fullWidth sx={{m: 1}}>
                        <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-email"
                            label="Email"
                            name={'email'}
                            defaultValue={contactData?.email}
                            onChange={handleChangeValues}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{m: 1}}>
                        <InputLabel htmlFor="outlined-adornment-phone">Phone</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-phone"
                            label="Phone"
                            name={'phone'}
                            defaultValue={contactData?.phone}
                            onChange={handleChangeValues}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{m: 1}}>
                        <InputLabel htmlFor="outlined-adornment-address">Address</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-address"
                            label="Address"
                            name={'address'}
                            defaultValue={contactData?.address}
                            onChange={handleChangeValues}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{m: 1}}>
                        <InputLabel htmlFor="outlined-adornment-instagram">Instagram</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-instagram"
                            label="Instagram"
                            name={'instagram'}
                            defaultValue={contactData?.instagram}
                            onChange={handleChangeValues}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{m: 1}}>
                        <InputLabel htmlFor="outlined-adornment-facebook">Facebook</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-facebook"
                            label="Facebook"
                            name={'facebook'}
                            defaultValue={contactData?.facebook}
                            onChange={handleChangeValues}
                        />
                    </FormControl>
                    <Button variant="contained" color="primary" onClick={handleClick}>
                        Update
                    </Button>
                </div>
            </Box>
        </>
    )
}

export default Contacts