import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ToggleButton from "@mui/material/ToggleButton";

import {createUser} from "../../store/actions/userAction";
import {useDispatch} from "react-redux";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function UserModal() {
    const [open, setOpen] = React.useState(false);
    const [userData, setUserData] = React.useState({
        email: '',
        password: '',
        role: 0
    })

    let dispatch = useDispatch()

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleChange = e => {
        userData[e.target.name] = e.target.value;
        setUserData(userData)
    }

    const handleCreate = () => {
        dispatch(createUser(userData))
        handleClose()
        setUserData({
            email: '',
            password: '',
            role: 0
        })
    }

    return (
        <div>
            <Button onClick={handleOpen}><GroupAddIcon/></Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <FormControl fullWidth sx={{m: 1}}>
                            <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-email"
                                onChange={handleChange}
                                label="Email"
                                name={'email'}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Role</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Role"
                                defaultValue={userData.role}
                                name={'role'}
                                onChange={handleChange}
                            >
                                <MenuItem value={0}>User</MenuItem>
                                <MenuItem value={1}>Administrator</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{m: 1}}>
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                onChange={handleChange}
                                label="Password"
                                name={'password'}
                                type={'password'}
                            />
                        </FormControl>
                        <ToggleButton value="list" aria-label="list" onClick={handleCreate}>
                            Create
                        </ToggleButton>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}