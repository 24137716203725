import {combineReducers} from "redux";
import {userReducer} from "./reducers/userReducer";
import {aboutReducer} from "./reducers/aboutReducer";
import {contactReducer} from "./reducers/contactReducer";
import {productReducer} from "./reducers/productReducer";
import {orderReducer} from "./reducers/orderReducer";
import {settingReducer} from "./reducers/settingReducer";

export const rootReducer = combineReducers({
    user: userReducer,
    about: aboutReducer,
    contact: contactReducer,
    product: productReducer,
    order: orderReducer,
    setting: settingReducer
})