import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TablePagination from "@mui/material/TablePagination";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
    deleteOrder,
    deleteOrderFromTable,
    getAllOrders,
    getOrders,
    getTableOrders
} from "../../store/actions/orderAction";
// import Accordion from "@mui/material/Accordion";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import AccordionDetails from "@mui/material/AccordionDetails";

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import OrderAccordion from "./OrderAccordion";
import ToggleButton from "@mui/material/ToggleButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { deleteUser } from "../../store/actions/userAction";
import keys from './../../keys';

function createData(order) {
    return {
        id: order.id,
        address: order.address,
        amount: order.amount,
        products: order.products,
        // delivery: order.delivery,
        phone: order.phone,
        second_phone: order.second_phone,
        firstName: order.firstName,
        lastName: order.lastName,
        email: order.email,
        user: order.User
    };
}

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function Row(props) {
    const { row } = props;
    console.log(row, 'row')
    const [open, setOpen] = React.useState(false);
    const [count, setCount] = useState([])
    // const [expanded, setExpanded] = React.useState(row.id);
    // const handleChange = (panel) => (event, newExpanded) => {
    //     setExpanded(newExpanded ? panel : false);
    // };
    console.log(count, 'count')
    console.log(open, 'open')

    const dispatch = useDispatch()
    const deleteOrderHandler = () => dispatch(deleteOrder(row.id))

    const openDetails = (products) => {
        if (!open) dispatch(getTableOrders(products))
        if (open) {
            dispatch(deleteOrderFromTable())
            // setFinishedPrice(finishedPrice)
        }

        setOpen(!open)
    }

    const ordersInTable = useSelector(state => state.order.ordersInTable)
    console.log(ordersInTable, 'ordersInTableordersInTableordersInTableordersInTable')


    let quantity = ({ idx, order }) => {
        let quantity = JSON.parse(row.products)[idx]?.id === order?.id ? `x${JSON.parse(row.products)[idx].quantity}` : null
        return quantity
    }

    let finishedPrice = []

    let calculateTotal = ({ idx, order }) => {
        let quantity = JSON.parse(row.products)[idx]?.id === order?.id ? JSON.parse(row.products)[idx].quantity : null
        let total = Number(quantity) * Number(order.price_amd)
        finishedPrice.push(total)
        // setFinishedPrice(finishedPrice)
        return total
    }


    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => openDetails(row.products)}

                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

                <TableCell><b>{row.email}</b></TableCell>
                <TableCell align="right"><b>{row.address}</b></TableCell>
                <TableCell align="right"><b>{row.phone}</b></TableCell>
                <TableCell align="right"><b>{row.second_phone}</b></TableCell>
                <TableCell align="right"><b>{row.firstName}</b></TableCell>
                <TableCell align="right"><b>{row.lastName}</b></TableCell>
                <TableCell align="right"><b>{row.amount}</b></TableCell>
                <TableCell align="right"><b>{row.description}</b></TableCell>
                <TableCell align="right"><b>{row.deleveryDate}</b></TableCell>
                <TableCell align="right"><b>{row.deleveryTime}</b></TableCell>

                <TableCell align="right"><b>{row.status === "true" ? <p>
                    <mark>{'Վճարված է'}</mark>
                </p> : row.status === "wait" ? <p>{'Կատարվում է...'}</p> : <p>{'Վճարված չէ'}</p>}</b></TableCell>
                <TableCell style={{ width: 160 }} align="right">
                    <ToggleButton value="left" key="left" onClick={deleteOrderHandler}>
                        <DeleteOutlineIcon />
                    </ToggleButton>
                </TableCell>
                {/*<TableCell align="right">{row.createdAt.slice(0,10)}</TableCell>*/}
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Products
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    {
                                        ordersInTable?.length
                                            ? ordersInTable.map((order, index) => (
                                                <TableRow key={order.id}>
                                                    <TableCell component="th" scope="row">
                                                        <img src={order.images.split(',')[0]}
                                                            alt=""
                                                            style={{ width: '180px', height: '120px', objectFit: 'cover' }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>{order.titleHy}</TableCell>
                                                    <TableCell align="right">{order.availabilityHy}</TableCell>
                                                    <TableCell align="right">{order.model}</TableCell>
                                                    <TableCell align="right">{order.price_amd} AMD</TableCell>
                                                    <TableCell align="right">
                                                        {quantity({ idx: index, order })}
                                                        {/*{JSON.parse(row.products)[index]?.id === order?.id ? JSON.parse(row.products)[index].quantity : null}*/}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {calculateTotal({ idx: index, order })} AMD
                                                    </TableCell>
                                                    <TableCell align="right">{order.deleveryDate}</TableCell>
                                                    <TableCell align="right">{order.deleveryTime}</TableCell>
                                                </TableRow>
                                            ))
                                            : null
                                    }
                                </TableBody>
                                <div>
                                    {
                                        finishedPrice.length
                                            ? <p>Final price: {finishedPrice?.reduce((a, b) => a + b)} AMD</p>
                                            : null
                                    }

                                </div>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>


        </React.Fragment>
    );
}

export default function OrderTable() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const dispatch = useDispatch()
    // const allOrders = useSelector(state => state?.order.allOrders)

    useEffect(() => {
        dispatch(getOrders())
        // dispatch(getAllOrders())
    }, [])


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const orders = useSelector(state => state.order.orders)

    const rows = orders?.map(order => createData(order))

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    return (
        <>
            <TableContainer component={Paper}>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell><b>Email</b></TableCell>
                            <TableCell align="right"><b>Address</b></TableCell>
                            <TableCell align="right"><b>Phone</b></TableCell>
                            <TableCell align="right"><b>Phone 2</b></TableCell>
                            <TableCell align="right"><b>First name</b></TableCell>
                            <TableCell align="right"><b>Last name</b></TableCell>
                            <TableCell align="right"><b>Amount</b></TableCell>
                            <TableCell align="right"><b>Description</b></TableCell>
                            <TableCell align="right"><b>Delevry Date</b></TableCell>
                            <TableCell align="right"><b>Delevry Time</b></TableCell>
                            <TableCell align="right"><b>Status</b></TableCell>
                            <TableCell align="right"><b>Delete Order</b></TableCell>
                        </TableRow>
                    </TableHead>
                </Table>


                <Table aria-label="collapsible table">
                    <TableBody>

                        {orders
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(row => <>
                                <Accordion expanded={expanded === `${row.id}`} onChange={handleChange(`${row.id}`)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >

                                        <Typography sx={{ color: 'text.secondary' }}>օնլայն վճարում
                                            N {row.id}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Row key={row.id} row={row} />

                                    </AccordionDetails>
                                </Accordion>
                                <br />

                            </>)
                        }

                    </TableBody>
                </Table>
            </TableContainer>

        </>

    );
}
