import {
    UPDATE_HOME_BANNER,
    GET_HOME_BANNER,
    GET_ABOUT_BANNER,
    UPDATE_ABOUT_BANNER,
    GET_SHORT_ABOUT_BANNER,
    CREATE_CATEGORY,
    GET_CATEGORIES,
    GET_SERVICES
} from "../types";

const initialState = {
    homeBanner: {},
    aboutBanner: {},
    shortAboutData: {},
    categories: [],
    services: []
}

export const settingReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_HOME_BANNER:
            return {
                ...state,
                homeBanner: action.payload,
            }
        case UPDATE_HOME_BANNER:
            return {
                ...state,
                homeBanner: action.payload
            }
        case GET_SERVICES:
            return {
                ...state,
                services: action.payload
            }
        case GET_ABOUT_BANNER:
            return {
                ...state,
                aboutBanner: action.payload,
            }
        case UPDATE_ABOUT_BANNER:
            return {
                ...state,
                aboutBanner: action.payload
            }
        case GET_SHORT_ABOUT_BANNER:
            return {
                ...state,
                shortAboutData: action.payload
            }
        case CREATE_CATEGORY: {
            return {
                ...state,
                categories: [{...action.payload}, ...state.categories]
            }
        }
        case GET_CATEGORIES: {
            return {
                ...state,
                categories: action.payload
            }
        }
        default:
            return state
    }
}