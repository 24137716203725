import React, {useEffect} from 'react'
import ProductTable from "../components/Product/ProductTable";
import ProductModal from "../components/Product/ProductModal";
import {useDispatch, useSelector} from "react-redux";
import {getProducts} from "../store/actions/productAction";

const Products = () => {
    const [open, setOpen] = React.useState(false);
    const [modalType, setModalType] = React.useState('')
    const [productItemInModal, setProductItemInModal] = React.useState(null)

    const handleOpen = data => {
        if(data.type === 'edit') setProductItemInModal(data.product)
        setOpen(true)
        setModalType(data.type)
    };
    const handleClose = () => {
        setOpen(false)
        setModalType('')
    };

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getProducts())
    }, [])

    const products = useSelector(state => state.product.products)

    return (
        <>
            <ProductModal handleOpen={handleOpen}
                          handleClose={handleClose}
                          open={open}
                          modalType={modalType}
                          productItemInModal={productItemInModal}
            />
            <ProductTable products={products}
                          handleOpen={handleOpen}
            />
        </>
    )
}

export default Products