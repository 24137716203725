import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServicesThunk } from "../../store/actions/settingAction";
import axios from "axios";
import keys from "../../keys";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { FormControl } from "@mui/material";
import Swal from "sweetalert2";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Services = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [img, setImg] = React.useState();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [file, setFile] = useState(null);
  const allServices = useSelector((state) => state.setting.services);
  useEffect(() => {
    dispatch(getServicesThunk());
  }, []);

  useEffect(() => {
    setData(allServices);
  }, [allServices]);

  const handleDelete = (id) => {
    axios
      .delete(`${keys.API_URI}/services/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(keys.JWT_SECRET)}`,
        },
        data: { id },
      })
      .then(function (response) {
        const newData = data.filter((i) => i.id !== id);
        setData(newData);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleFile = (e) => {
    let files = [];
    Object.keys(e.target.files).map((f) => {
      if (f === "Length") return;
      files.push(e.target.files[0]);
    });
    uploadImage(files);
  };

  let arrOfImages = [];
  const uploadImage = (files) => {
    console.log(files, "8888888888");
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("upload_preset", keys.CLOUDINARY_PRESENT);
    console.log(JSON.stringify(formData));
    axios
      .post(
        `https://api.cloudinary.com/v1_1/${keys.CLOUDINARY_CLOUD}/image/upload`,
        formData
      )
      .then((res) => {
        arrOfImages.push(res.data.url);
        console.log(data, "222222");
        data["images"] = arrOfImages;
        setData(data);
        setImg(data);
      });
  };

  const handleCreate = () => {
    console.log(img, "789");
    axios
      .post(
        `${keys.API_URI}/services/`,
        {
          image: img.images[0],
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(keys.JWT_SECRET)}`,
          },
        }
      )
      .then(function (response) {
          console.log(response.data,"123456")
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      <hr />
      <h4>Services</h4>
      <Button
        variant="contained"
        onClick={handleOpen}
        style={{
          margin: "20px 0",
        }}
      >
        +
      </Button>
      <Grid container spacing={2}>
        {data &&
          data.map((i, index) => {
            return (
              <Grid item xs={3}>
                <img src={i.image} style={{ width: "100%", height: "250px" }} />
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => handleDelete(i.id)}
                >
                  delete
                </Button>
              </Grid>
            );
          })}
      </Grid>
      <div className="serviceTableBox">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add Service Item
            </Typography>
            <FormControl fullWidth sx={{ m: 1 }}>
              <Button variant="contained" component="label">
                Upload Image
                <input type="file" hidden multiple onChange={handleFile} />
              </Button>
            </FormControl>
            <Button onClick={handleCreate}>Create</Button>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default Services;
