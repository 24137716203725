import React, { useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";

import UserTable from "../components/User/UserTable";
import UserModal from "../components/User/UserModal";

import {getUsers} from "../store/actions/userAction";

const Users = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUsers())
    }, [])

    const users = useSelector(state => state.user.users)

    return (
        <>
            <UserModal/>
            {
                users ? <UserTable users={users}/> : null
            }

        </>
    )
}

export default Users