import axios from "axios";
import keys from "../../keys";
import {UPDATE_ABOUT, GET_ABOUT} from "../types";
import Swal from "sweetalert2";

export function updateAbout(data) {
    return dispatch => {
        axios.patch(`${keys.API_URI}/about`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(keys.JWT_SECRET)}`
            },
        })
            .then(res => {
                dispatch({type: UPDATE_ABOUT, payload: data})
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    timer: 1500
                })
            })
            .catch(e => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    timer: 1500
                })
            })
    }
}

export function getAboutData() {
    return dispatch => {
        axios.get(`${keys.API_URI}/about`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(keys.JWT_SECRET)}`
            },
        })
            .then(res => {
                dispatch({type: GET_ABOUT, payload: res.data[0]})
            })
            .catch(e => console.log(e))
    }
}