import {GET_CONTACT} from "../types";

const initialState = {
    contactData: {},
}

export const contactReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CONTACT:
            return {
                ...state,
                contactData: action.payload,
            }
        default:
            return state
    }
}