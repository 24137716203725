import {GET_PRODUCTS, EDIT_PRODUCTS, DELETE_PRODUCTS, CREATE_PRODUCTS} from "../types";

const initialState = {
    products: [],
}

export const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCTS:
            return {
                ...state,
                products: action.payload,
            }
        case DELETE_PRODUCTS:
            return {
                ...state,
                products: state.products.filter(product => product.id !== action.payload)
            }
        case CREATE_PRODUCTS:
            return {
                ...state,
                products: [{...action.payload}, ...state.products]
            }
        case EDIT_PRODUCTS:
            let newArrayOfProducts = state.products
            const idx = newArrayOfProducts.map(product => product.id).indexOf(action.payload.id);
            Object.keys(action.payload).forEach((key, index) => {
                newArrayOfProducts[idx][key] = action.payload[key]
            })
            return {
                ...state,
                products: newArrayOfProducts
            }
        default:
            return state
    }
}