import {GET_USERS, DELETE_USER, CREATE_USER} from "../types";
import axios from "axios";
import Swal from "sweetalert2";
import keys from '../../keys'

export function getUsers() {
    return dispatch => {
        axios.get(`${keys.API_URI}/auth/all`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(keys.JWT_SECRET)}`
            },
            // params: {
            //     page: page || 1,
            // }
        })
            .then(res => {
                dispatch({type: GET_USERS, payload: res.data})
            })
            .catch(e => console.log(e))
    }
}

export function deleteUser(id) {
    return dispatch => {
        axios.delete(`${keys.API_URI}/auth/`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(keys.JWT_SECRET)}`
            },
            data: {id}
        })
            .then(res => {
                dispatch({type: DELETE_USER, payload: id})
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    timer: 1500
                })
            })
            .catch(e => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    timer: 1500
                })
            })
    }
}

export function createUser(data) {
    return dispatch => {
        axios.post(`${keys.API_URI}/auth/create`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(keys.JWT_SECRET)}`
            },
        })
            .then(res => {
                dispatch({type: CREATE_USER, payload: res.data})
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    timer: 1500
                })
            })
            .catch(e => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    timer: 1500
                })
            })
    }
}

