import React from 'react'
import OrderTable from "../components/Order/OrderTable";

const Orders = () => {
    return (
        <>
            <OrderTable/>
        </>
    )
}

export default Orders